import React, { useEffect, useRef, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  lighten,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import HistoryIcon from "@mui/icons-material/History";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import showNotification from "../../../commons/helpers/showNotification";
import { FontAwesomeIconStatus } from "../../../components/icon/FontAwesomeIconStatus";
import HighLabelStatus from "../../../components/labels/HighLabelStatus";
import SuperUserService from "../../../services/SuperUserService";
import LoginHistoryModal from "./LoginHistoryModal";
import UserReleaseEmailModal from "./UserReleaseEmail";

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  justifyContent: "space-between",
}));

const TableRowStyled = styled(TableRow)(({ theme, ismaster }) => {
  return {
    backgroundColor: ismaster && lighten(theme.palette.info.light, 0.7),
  };
});

// COMPONENT
function UsersModal({ selectedCompany, open, onClose, onUpdate }) {
  const refSelectedUserId = useRef();

  const [userList, setUserList] = useState([]);
  const [includeDeleted, setIncludeDeleted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [tempPasswordInfo, setTempPasswordInfo] = useState();
  const [loginHistoryModalOpen, setLoginHistoryModalOpen] = useState(false);
  const [releaseEmailInProgress, setReleaseEmailInProgress] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      SuperUserService.getUserListForCompany(selectedCompany.id, includeDeleted)
        .then((resp) => {
          setUserList(resp);
          setTempPasswordInfo(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, selectedCompany, includeDeleted]);

  const handleResetPassword = (selectedUser) => {
    const isOk = window.confirm(
      "Você tem certeza que deseja criar uma senha temporaria?"
    );
    if (isOk) {
      setIsLoading(true);
      SuperUserService.createTempPassword(selectedUser.id)
        .then((tempPassword) => {
          setTempPasswordInfo({ selectedUser, tempPassword });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleKick = (selectedUser) => {
    const isOk = window.confirm(
      "Você tem certeza que deseja KICKAR o usuário? Isso fara que ele tenha que fazer um novo login"
    );
    if (isOk) {
      setIsLoading(true);
      SuperUserService.kickUser(selectedUser.id)
        .then(() => {
          showNotification("success", "Usuario kickado com sucesso!");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleToggleBlocked = (selectedUser) => {
    const isOk = window.confirm(
      selectedUser.blocked
        ? "Você tem certeza que deseja desbloquear o funcionario?"
        : "Você tem certeza que deseja bloquear o funcionario?"
    );
    if (isOk) {
      setIsLoading(true);
      SuperUserService.toggleBlocked(selectedUser.id)
        .then((updated) => {
          showNotification("success", "Usuario atualizado com sucesso!");
          setUserList((prevDataSource) =>
            prevDataSource.map((obj) =>
              obj.id === selectedUser.id
                ? {
                    ...obj,
                    blocked: updated.blocked,
                  }
                : obj
            )
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const actualUserList = React.useMemo(() => {
    return userList.filter((p) => !p.agendaOnly);
  }, [userList]);
  const courtList = React.useMemo(() => {
    return userList.filter((p) => p.agendaOnly);
  }, [userList]);

  return (
    <>
      {refSelectedUserId.current && (
        <>
          <LoginHistoryModal
            userId={refSelectedUserId.current}
            open={loginHistoryModalOpen}
            onClose={() => {
              setLoginHistoryModalOpen(false);
            }}
          />
          <UserReleaseEmailModal
            userId={refSelectedUserId.current}
            modalVisible={releaseEmailInProgress}
            setModalVisible={() => {
              setReleaseEmailInProgress(false);
            }}
            onComplete={() => {
              setReleaseEmailInProgress(false);
              onClose();
            }}
          />
        </>
      )}

      <Dialog onClose={onClose} open={open} fullWidth maxWidth="xl">
        <DialogTitle>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">
              Usuários de {selectedCompany.name}
            </Typography>

            <Typography variant="h5">
              {userList.length} usuários de{" "}
              {selectedCompany.maxNumberOfUsersCourtesy
                ? selectedCompany.maxNumberOfUsersCourtesy +
                  selectedCompany.maxNumberOfUsers
                : selectedCompany.maxNumberOfUsers}{" "}
              ({selectedCompany.maxNumberOfUsers} do plano e{" "}
              {selectedCompany.maxNumberOfUsersCourtesy
                ? selectedCompany.maxNumberOfUsersCourtesy
                : 0}{" "}
              cortesias)
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={includeDeleted}
                  onChange={(e) => {
                    setIncludeDeleted(e.target.checked);
                  }}
                  size="small"
                  name="checkedB"
                />
              }
              label="Incluir deletados"
            />
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Grid item xs={12}>
            {courtList.length > 0 && (
              <Typography gutterBottom variant="caption">
                Usuários:
              </Typography>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Master?</TableCell>
                  <TableCell>Permissão</TableCell>
                  <TableCell>Force Reset</TableCell>
                  <TableCell>Instrutor?</TableCell>
                  <TableCell>Ativo?</TableCell>
                  <TableCell>Bloqueado?</TableCell>
                  {includeDeleted && (
                    <>
                      <TableCell>Deleted?</TableCell>
                      <TableCell>Deleted as</TableCell>
                      <TableCell />
                    </>
                  )}
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {actualUserList.map((u) => {
                  return (
                    <TableRowStyled key={u.id} ismaster={u.master ? 1 : 0}>
                      <TableCell>{u.name}</TableCell>
                      <TableCell>{u.email}</TableCell>
                      <TableCell>
                        {u.master ? "Sim" : "Não"}

                        {!u.master && (
                          <IconButton
                            edge="end"
                            onClick={() => {
                              const confirmed = window.confirm(
                                "Are you want to change the user master?"
                              );
                              if (confirmed) {
                                setIsLoading(true);
                                SuperUserService.changeCompanyMaster({
                                  companyId: selectedCompany.id,
                                  masterUserId: u.id,
                                })
                                  .then((companyUpdated) => {
                                    onUpdate({
                                      masterUserId: u.id,
                                      company: companyUpdated,
                                    });
                                    onClose();
                                  })
                                  .finally(() => {
                                    setIsLoading(false);
                                  });
                              }
                            }}
                            color="primary"
                          >
                            <TransferWithinAStationIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell>
                        {u.roles.map((r) => r.name).join(", ")}
                      </TableCell>
                      <TableCell>
                        {u.forceResetPassword ? "Sim" : "Não"}
                      </TableCell>
                      <TableCell>{u.instructor ? "Sim" : "Não"}</TableCell>
                      <TableCell>{u.enabled ? "Sim" : "Não"}</TableCell>
                      <TableCell>
                        {u.blocked ? (
                          <HighLabelStatus status="error">Sim</HighLabelStatus>
                        ) : (
                          "Não"
                        )}
                        {!u.master && (
                          <IconButton
                            edge="end"
                            onClick={() => {
                              handleToggleBlocked(u);
                            }}
                            color="primary"
                          >
                            <FontAwesomeIconStatus
                              icon={u.blocked ? "toggle-on" : "toggle-off"}
                              status={u.blocked ? "success" : "primary"}
                            />
                          </IconButton>
                        )}
                      </TableCell>
                      {includeDeleted && (
                        <>
                          <TableCell>
                            {u.deleted ? (
                              <HighLabelStatus status="error">
                                Sim
                              </HighLabelStatus>
                            ) : (
                              "Não"
                            )}
                          </TableCell>
                          <TableCell>{u.deletedAt}</TableCell>
                          {/* <TableCell>
                            {u.deleted && (
                              <Button
                                disabled={isLoading}
                                onClick={() => {
                                  handleRecoverEmployee(u);
                                }}
                              >
                                Recuperar
                              </Button>
                            )}
                          </TableCell> */}
                          <TableCell>
                            {u.deleted && (
                              <Button
                                disabled={isLoading}
                                onClick={() => {
                                  // handleSchedulesEmployee(u);
                                }}
                              >
                                Agendamentos
                              </Button>
                            )}
                          </TableCell>
                        </>
                      )}
                      <TableCell>
                        <Button
                          disabled={isLoading}
                          onClick={() => {
                            handleResetPassword(u);
                          }}
                        >
                          Reset Pwd
                        </Button>
                      </TableCell>
                      {u.email != null && !u.master ? (
                        <TableCell>
                          <Button
                            disabled={isLoading}
                            onClick={() => {
                              refSelectedUserId.current = u.id;
                              setReleaseEmailInProgress(true);
                            }}
                          >
                            Liberar Email
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                      <TableCell>
                        <IconButton
                          edge="end"
                          onClick={() => {
                            refSelectedUserId.current = u.id;
                            setLoginHistoryModalOpen(true);
                          }}
                          color="primary"
                        >
                          <HistoryIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          edge="end"
                          onClick={() => {
                            handleKick(u);
                          }}
                          color="primary"
                        >
                          <FontAwesomeIconStatus
                            icon="sign-out-alt"
                            status="primary"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRowStyled>
                  );
                })}
              </TableBody>
            </Table>

            {tempPasswordInfo && (
              <Alert>
                <Typography>Temp password info:</Typography>
                <Typography variant="body2">
                  User:{" "}
                  <Typography component="span">
                    {tempPasswordInfo.selectedUser.email}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  Pswd:{" "}
                  <Typography component="span">
                    {tempPasswordInfo.tempPassword}
                  </Typography>
                </Typography>
              </Alert>
            )}

            {courtList.length > 0 && (
              <div>
                <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
                <Typography gutterBottom variant="caption">
                  Quadras:
                </Typography>

                <Table style={{ width: "600px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Ativo?</TableCell>
                      {includeDeleted && (
                        <>
                          <TableCell>Deleted?</TableCell>
                          <TableCell>Deleted as</TableCell>
                          <TableCell />
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {courtList.map((u) => {
                      return (
                        <TableRow key={u.id}>
                          <TableCell>{u.name}</TableCell>
                          <TableCell>{u.enabled ? "Sim" : "Não"}</TableCell>
                          {includeDeleted && (
                            <>
                              <TableCell>
                                {u.deleted ? (
                                  <HighLabelStatus status="error">
                                    Sim
                                  </HighLabelStatus>
                                ) : (
                                  "Não"
                                )}
                              </TableCell>
                              <TableCell>{u.deletedAt}</TableCell>
                              <TableCell>
                                {u.deleted && (
                                  <Button
                                    disabled={isLoading}
                                    onClick={() => {
                                      //handleSchedulesEmployee(u);
                                    }}
                                  >
                                    Agendamentos
                                  </Button>
                                )}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
          </Grid>
        </DialogContent>

        <DialogActionsStyled>
          <Button variant="outlined" onClick={onClose}>
            Fechar
          </Button>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default UsersModal;

import HttpService from "./commons/HttpService";

const SuperUserService = {
  getCompanyInfo({ phoneOrEmail, emailOnly }) {
    return HttpService.get("/superUser/companyInfo", {
      params: { phoneOrEmail, emailOnly },
    });
  },
  getCompanyInfoByCompanyId({ companyId }) {
    return HttpService.get("/superUser/companyInfoByCompanyId", {
      params: { companyId },
    });
  },
  createTempAccessForCompany(companyId, extraParams, origin) {
    return HttpService.post("/superUser/tempAccessForCompany", {
      companyId,
      extraParams,
      origin,
    });
  },
  getCompanyInfoCpfCnpj({ cpfCnpj }) {
    return HttpService.get("/superUser/companyInfoByCpfCnpj", {
      params: { cpfCnpj },
    });
  },
  getCompanyInfoDeleted({ phoneOrEmail, emailOnly }) {
    return HttpService.get("/superUser/companyInfoDeleted", {
      params: { phoneOrEmail, emailOnly },
    });
  },
  updatePhoneNumber(companyPhoneNumberForm) {
    return HttpService.post(
      "/superUser/companyPhoneNumber",
      companyPhoneNumberForm
    );
  },
  updateEmailAddress(companyEmailAddressForm) {
    return HttpService.post(
      "/superUser/companyEmailAddress",
      companyEmailAddressForm
    );
  },
  getUserListForCompany(companyId, includeDeleted) {
    return HttpService.get("/superUser/userListForCompany", {
      params: { companyId, includeDeleted },
    });
  },
  getLoginHistory(userId) {
    return HttpService.get("/superUser/loginHistory", { params: { userId } });
  },
  createTempPassword(userId) {
    return HttpService.post("/superUser/createTempPassword", {
      userId,
    });
  },
  deleteUnusedCustomers(companyId) {
    return HttpService.post('/superUser/deleteUnusedCustomers', { companyId });
  },
  updateMaxNumberOfUsers(companyInfoForm) {
    return HttpService.post("/superUser/updateMaxNumberUsers", companyInfoForm);
  },
  updateMaxNumberOfUsersCourtesy(companyInfoForm) {
    return HttpService.post(
      "/superUser/updateMaxNumberUsersCourtesy",
      companyInfoForm
    );
  },
  searchUsers(nameOrEmail) {
    return HttpService.get("/superUser/searchUsers", {
      params: { nameOrEmail },
    });
  },
  releaseEmailSendConfirmationCode(userId) {
    return HttpService.post("/superUser/releaseEmailSendConfirmationCode", {
      userId,
    });
  },
  releaseEmailResendConfirmationCode(userId) {
    return HttpService.post("/superUser/releaseEmailResendConfirmationCode", {
      userId,
    });
  },
  releaseEmailConfirmCode(releaseEmailConfirmCode) {
    return HttpService.post(
      "/superUser/releaseEmailConfirmCode",
      releaseEmailConfirmCode
    );
  },
  getReleaseEmail(userId) {
    return HttpService.get("/superUser/releaseEmail", { params: { userId } });
  },
  kickUser(userId) {
    return HttpService.post("/superUser/kickUser", {
      userId,
    });
  },
  toggleBlocked(userId) {
    return HttpService.post('/superUser/toggleBlocked', {
      userId,
    });
  },
  changeCompanyMaster({ companyId, masterUserId }) {
    return HttpService.post("/superUser/companyMaster", {
      companyId,
      masterUserId,
    });
  },
  updateAccountType(accountTypeForm) {
    return HttpService.post("/superUser/accountType", accountTypeForm);
  },
  changeSubscriptionType({
    companyId,
    subscriptionType,
    lastPaidAt,
    subscriptionExpiryDate,
  }) {
    return HttpService.post("/superUser/subscriptionTypeSettings", {
      companyId,
      subscriptionType,
      lastPaidAt,
      subscriptionExpiryDate,
    });
  },
  getAndroidSubscriptionInfo(androidReceiptHistoryId) {
    return HttpService.get("/superUser/androidSubscriptionInfo", {
      params: { androidReceiptHistoryId },
    });
  },
  getAndroidHistorySubscription(companyId) {
    return HttpService.get("/superUser/androidHistorySubscription", {
      params: { companyId },
    });
  },
  getAndroidHistorySubscriptionDetail(androidReceiptHistoryId) {
    return HttpService.get("/superUser/androidHistorySubscriptionDetail", {
      params: { androidReceiptHistoryId },
    });
  },
  getAppleHistorySubscription(companyId) {
    return HttpService.get("/superUser/appleHistorySubscription", {
      params: { companyId },
    });
  },
  getAppleHistorySubscriptionDetail(appleReceiptHistoryId) {
    return HttpService.get("/superUser/appleHistorySubscriptionDetail", {
      params: { appleReceiptHistoryId },
    });
  },
  getAppleSubscriptionInfo(appleReceiptHistoryId) {
    return HttpService.get("/superUser/appleSubscriptionInfo", {
      params: { appleReceiptHistoryId },
    });
  },
  getSherlockHolmesInfo() {
    return HttpService.get("/superUser/sherlockHolmes");
  },

  connectSherlockHolmesInfo(companyId) {
    return HttpService.put("/superUser/sherlockHolmes", {
      companyId,
    });
  },
  disconnectSherlockHolmesInfo() {
    return HttpService.delete("/superUser/sherlockHolmes");
  },

  // ASAAS
  getAsaasCustomers() {
    return HttpService.get("/superUser/asaasCustomers");
  },

  updateAsaasCustomer(asaasInfo) {
    return HttpService.post("/superUser/asaasInfo", asaasInfo);
  },

  createAsaasSubscription(form) {
    return HttpService.post("/superUser/asaasSubscription", form);
  },

  upgradeAsaasSubscription(form) {
    return HttpService.post("/superUser/asaasSubscriptionUpgrade", form);
  },

  asaasSubscriptionProRataInfo(form) {
    return HttpService.post("/superUser/asaasSubscriptionProRataInfo", form);
  },

  createAsaasCharge(form) {
    return HttpService.post("/superUser/asaasCharge", form);
  },

  deleteAsaasCharge({ companyId, chargeId }) {
    return HttpService.delete("/superUser/asaasCharge", {
      params: { companyId, chargeId },
    });
  },

  deleteAsaasSubscription(companyId) {
    return HttpService.delete("/superUser/asaasSubscription", {
      params: { companyId },
    });
  },

  getAsaasPlan(maxNumberOfUsers) {
    return HttpService.get("/superUser/asaasPlan", {
      params: { maxNumberOfUsers },
    });
  },

  getAsaasSubscription(companyId) {
    return HttpService.get("/superUser/asaasSubscription", {
      params: { companyId },
    });
  },

  getLastPendingSubscriptionPayment(companyId) {
    return HttpService.get("/superUser/asaasSubscriptionLastPendingPayments", {
      params: { companyId },
    });
  },

  getAsaasPaymentHistory(asaasCustomerId, limit) {
    return HttpService.get("/superUser/asaasPaymentInfo", {
      params: { asaasCustomerId, limit },
    });
  },

  upgradeAsaasPayment(form) {
    return HttpService.post("/superUser/asaasPaymentUpdate", form);
  },

  updateAsaasSubscription(form) {
    return HttpService.post("/superUser/asaasSubscriptionUpdate", form);
  },

  getAsaasDiscount(companyId) {
    return HttpService.get("/superUser/asaasPaymentUpdate", {
      params: { companyId },
    });
  },

  deleteAsaas(companyId) {
    return HttpService.delete("/superUser/asaas", { params: { companyId } });
  },

  sendPushNotification({ companyId, notificationType, silent }) {
    return HttpService.post("/superUser/pushNotification", {
      companyId,
      notificationType,
      silent,
    });
  },
};

export default SuperUserService;

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import ReceiptIcon from "@mui/icons-material/Receipt";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Chip, IconButton, styled, Typography } from "@mui/material";
import copy from "copy-to-clipboard";
import dayjs from "dayjs";
import React, { useState } from "react";
import {
  ACC_STATUS_PAID,
  ACC_STATUS_PAID_GRACE_PERIOD,
  ACC_STATUS_TRIAL_ACTIVE,
  ACC_STATUS_TRIAL_EXPIRED_OR_FREE,
  getAccountStatusDescription,
  getAccountStatusName,
  PAYMENT_TYPE_ANDROID,
  PAYMENT_TYPE_APPLE,
  PAYMENT_TYPE_ASAAS,
} from "../../commons/helpers/accountHelpers";
import showNotification from "../../commons/helpers/showNotification";
import HighLabelStatus from "../../components/labels/HighLabelStatus";
import ConfirmationDialog from "../../components/modals/ConfirmationDialog";
import SuperUserService from "../../services/SuperUserService";
import AsaasAccountInfoModal from "./asaas/AsaasAccountInfoModal";
import CustomChip from "./CustomChip";
import AndroidAccountInfoModal from "./modals/AndroidAccountInfoModal";
import AppleAccountInfoModal from "./modals/AppleAccountInfoModal";
import ChangeAccountTypeModal from "./modals/ChangeAccountTypeModal";
import ManageSubscriptionTypeInfoModal from "./modals/ManageSubscriptionTypeInfoModal";

function selectChipStyledThemeColor(statusName) {
  switch (statusName) {
    case ACC_STATUS_PAID:
      return "success";
    case ACC_STATUS_TRIAL_ACTIVE:
      return "info";
    case ACC_STATUS_TRIAL_EXPIRED_OR_FREE:
      return "error";
    case ACC_STATUS_PAID_GRACE_PERIOD:
      return "warning";
    default:
      return showNotification("error", "Opção inválida.");
  }
}

const ChipStyled = styled(Chip)(({ theme, accountstatus }) => {
  const themeColor = theme.palette[selectChipStyledThemeColor(accountstatus)];
  return {
    color: themeColor.contrastText,
    backgroundColor: themeColor.main,
    "&:hover": {
      backgroundColor: themeColor.dark,
    },
    "&:focus": {
      backgroundColor: themeColor.dark,
    },
  };
});

const getSubscriptionExpiryDateInfo = (subscriptionExpiryDate) => {
  if (subscriptionExpiryDate == null) {
    return null;
  }
  const expiryDate = dayjs(subscriptionExpiryDate);
  const daysRemaning = Math.ceil(expiryDate.diff(dayjs(), "days", true));
  return {
    isExpired: daysRemaning < 0,
    subscriptionExpiryDateFormatted: expiryDate.format("DD/MM/YY"),
  };
};

// COMPONENT
function AccountTypeInfo({ company, onUpdateCompany }) {
  const [changeAccountTypeModalOpen, setChangeAccountTypeModalOpen] =
    useState(false);
  const [manageAsaasInfoModalOpen, setManageAsaasInfoModalOpen] =
    useState(false);
  const [
    manageSubscriptionTypeInfoModalOpen,
    setManageSubscriptionTypeInfoModalOpen,
  ] = useState(false);
  const accountStatusName = getAccountStatusName(company);
  const accountStatusDescription = getAccountStatusDescription(company);
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfirm, setModalConfirm] = useState({ open: false });
  const [androidAccountInfoModalOpen, setAndroidAccountInfoModalOpen] =
    useState(false);
  const [appleAccountInfoModalOpen, setAppleAccountInfoModalOpen] =
    useState(false);

  const openConfirmationModal = (
    title,
    message,
    confirmAction,
    textConfirm,
    note,
    amount,
    alert
  ) => {
    setModalConfirm({
      open: true,
      title: title,
      message: message,
      handleConfirmation: confirmAction,
      textConfirm: textConfirm,
      note: note,
      amount: amount,
      alert: alert,
    });
  };
  const handleGenerateAsaasLinkConfirmation = () => {
    setIsLoading(true);
    SuperUserService.createTempAccessForCompany(company.id)
      .then((companyTempAccessCode) => {
        if (companyTempAccessCode) {
          const link = `Prontinho, *é só clicar no link para obter o código PIX* \nhttps://portal.minhaagendaapp.com.br/asaas?code=${companyTempAccessCode.code}`;
          copy(link);
          showNotification("success", "Link copiado com sucesso!");
        } else {
          showNotification("error", "Não foi possivel gerar link!");
        }
      })
      .finally(() => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        setIsLoading(false);
      });
  };

  const subscriptionExpiryDateInfo = getSubscriptionExpiryDateInfo(
    company.subscriptionExpiryDate
  );
  return (
    <Box sx={{ mb: 1 }}>
      <ChangeAccountTypeModal
        selectedCompany={company}
        open={changeAccountTypeModalOpen}
        onClose={() => {
          setChangeAccountTypeModalOpen(false);
        }}
        onSave={(updatedAccountTypeDetails) => {
          setChangeAccountTypeModalOpen(false);
          onUpdateCompany({ ...company, ...updatedAccountTypeDetails });
        }}
      />
      {/* MODALS */}
      <ConfirmationDialog
        title={modalConfirm.title}
        message={modalConfirm.message}
        onConfirm={modalConfirm.handleConfirmation}
        open={modalConfirm.open}
        textConfirm={modalConfirm.textConfirm}
        note={modalConfirm.note}
        amount={modalConfirm.amount}
        alert={modalConfirm.alert}
        onClose={() => {
          setModalConfirm((prev) => ({ ...prev, open: false }));
        }}
      />

      <ManageSubscriptionTypeInfoModal
        selectedCompany={company}
        open={manageSubscriptionTypeInfoModalOpen}
        onClose={() => {
          setManageSubscriptionTypeInfoModalOpen(false);
        }}
        onSave={(companyUpdated) => {
          setManageSubscriptionTypeInfoModalOpen(false);
          onUpdateCompany({ ...companyUpdated });
        }}
      />

      <AsaasAccountInfoModal
        selectedCompany={company}
        open={manageAsaasInfoModalOpen}
        openConfirmationModal={openConfirmationModal}
        setManageAsaasInfoModalOpen={setManageAsaasInfoModalOpen}
        setModalConfirm={setModalConfirm}
        onClose={() => {
          setManageAsaasInfoModalOpen(false);
        }}
        onSave={(companyUpdated) => {
          setManageAsaasInfoModalOpen(false);
          onUpdateCompany({ ...companyUpdated });
        }}
      />
      <AndroidAccountInfoModal
        open={androidAccountInfoModalOpen}
        onClose={() => {
          setAndroidAccountInfoModalOpen(false);
        }}
        selectedCompany={company}
        onDelete={() => {
          setAndroidAccountInfoModalOpen(false);
        }}
      />
      <AppleAccountInfoModal
        open={appleAccountInfoModalOpen}
        onClose={() => {
          setAppleAccountInfoModalOpen(false);
        }}
        selectedCompany={company}
      />

      {/* MODALS */}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Typography sx={{ mr: 1 }}>Tipo de Conta:</Typography>
        <ChipStyled
          accountstatus={accountStatusName}
          label={accountStatusDescription}
          color="primary"
          onClick={() => {
            setChangeAccountTypeModalOpen(true);
          }}
          icon={
            accountStatusName === ACC_STATUS_PAID ||
            accountStatusName === ACC_STATUS_TRIAL_ACTIVE ? (
              <CheckCircleIcon />
            ) : (
              <WarningIcon />
            )
          }
          clickable
        />
        {accountStatusName === ACC_STATUS_PAID_GRACE_PERIOD &&
          dayjs(dayjs()).diff(company.paidGracePeriodEndDate, "days") > 0 && (
            <HighLabelStatus
              status={
                dayjs(dayjs()).diff(company.paidGracePeriodEndDate, "days") > 21
                  ? "error"
                  : "info"
              }
              style={{ marginLeft: "10px" }}
              variant="body2"
            >
              Plano expirou há{" "}
              {dayjs(dayjs()).diff(company.paidGracePeriodEndDate, "days")} dias
            </HighLabelStatus>
          )}
        {accountStatusName === ACC_STATUS_TRIAL_EXPIRED_OR_FREE &&
          dayjs(dayjs()).diff(company.trialExpirationDate, "days") > 0 && (
            <HighLabelStatus style={{ marginLeft: "10px" }} status="error">
              Trial expirou há{" "}
              {dayjs(dayjs()).diff(company.trialExpirationDate, "days")} dias
            </HighLabelStatus>
          )}
      </Box>
      {/* PAYMENT METHODS */}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Typography sx={{ mr: 1 }}>Assinatura:</Typography>
        <CustomChip
          label="Asaas"
          disabled={isLoading || company.asaasCustomerId == null}
          color={
            company.subscriptionType === PAYMENT_TYPE_ASAAS
              ? "primary"
              : "default"
          }
          onClick={() => {
            if (company.asaasCustomerId) {
              const asaasUrlPrefix =
                process.env.NODE_ENV === "production" ? "www" : "sandbox";
              window.open(
                `https://${asaasUrlPrefix}.asaas.com/customerAccount/show/${company.asaasCustomerId}`,
                "_blank"
              );
            }
          }}
        >
          <IconButton
            edge="end"
            disabled={isLoading}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setManageAsaasInfoModalOpen(true);
            }}
          >
            <InfoIcon color="success" />
          </IconButton>
          {company.asaasCustomerId !== null && (
            <IconButton
              edge="end"
              sx={{ ml: 1.2 }}
              disabled={isLoading || company.asaasCustomerId == null}
              onClick={(e) => {
                openConfirmationModal(
                  "Gerar Link de Asaas",
                  "Você tem certeza que deseja gerar o link de ativação? Ele dura 21 dias e se vc ja tiver gerado outro para este usuario, o link antigo ira se invalidar.",
                  handleGenerateAsaasLinkConfirmation
                );
              }}
            >
              <ReceiptIcon color="success" />
            </IconButton>
          )}
          {subscriptionExpiryDateInfo && (
            <Typography
              sx={{
                color: subscriptionExpiryDateInfo.isExpired ? "orange" : "lime",
                ml: 1,
              }}
              variant="body2"
            >
              {subscriptionExpiryDateInfo.subscriptionExpiryDateFormatted}
            </Typography>
          )}
        </CustomChip>

        <Chip
          label="Android"
          clickable
          disabled={isLoading || company.androidReceiptHistoryId == null}
          sx={{ mr: 1 }}
          color={
            company.subscriptionType === PAYMENT_TYPE_ANDROID
              ? "primary"
              : "default"
          }
          onClick={() => {
            setAndroidAccountInfoModalOpen(true);
          }}
        />

        <Chip
          label="Apple"
          clickable
          disabled={isLoading || company.appleReceiptHistoryId == null}
          color={
            company.subscriptionType === PAYMENT_TYPE_APPLE
              ? "primary"
              : "default"
          }
          onClick={() => {
            setAppleAccountInfoModalOpen(true);
          }}
        />

        <IconButton
          edge="end"
          disabled={isLoading}
          onClick={(e) => {
            setManageSubscriptionTypeInfoModalOpen(true);
          }}
        >
          <EditIcon color="primary" />
        </IconButton>
      </Box>

      <Typography gutterBottom>
        Data do Pagamento:{" "}
        <Typography color="textSecondary" component="span">
          {company.lastPaidAt != null
            ? dayjs(company.lastPaidAt).format("DD/MM/YYYY HH:mm")
            : "-"}
        </Typography>
      </Typography>
    </Box>
  );
}

export default AccountTypeInfo;

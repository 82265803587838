import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import FontAwesomeDoubleIconStatus from '../../components/icon/FontAwesomeDoubleIconStatus';


const ActionButton = {
  BIO: 0,
  AGENDA: 1,
  USUARIOS: 2,
  HISTORICO_LOGIN: 3,
  LOGS_REC_SENHA: 4,
  CREATE_TEMP_SENHA: 5,
  LINK_ATIVACAO: 6,
  LINK_UPGRADE: 7,
  ACCOUNT_DELETE: 8,
  ACCOUNT_RESET: 9,
  SEND_EMAIL: 10,
  EXPEDIENTE: 11,
  EXPORTAR_AGENDA: 12,
  EXPORTAR_AGENDA_COM_PAGAMENTOS: 13,
  IMPORTAR_CLIENTES: 14,
  EXPORTAR_CLIENTES: 15,
  DELETAR_CLIENTES: 16,
  ENVIAR_NOTIFICACAO: 17,
  PAINEL_ASSINATURA: 18,
  MUDAR_VENCIMENTO_STRIPE: 19,
  IMPORTAR_AGENDA: 20,
  LINK_ATIVACAO_ASAAS: 21,
};

function MainButtons({ onClickButton, selectedCompany }) {
  const buttons = [
    {
      type: ActionButton.DELETAR_CLIENTES,
      title: 'Deletar Clientes',
      modalConfirmMessage:
        'Você tem certeza que deseja deletar todos os clientes sem atendimento, comanda, vendas?',
      textConfirm: 'DELETAR CLIENTES',
      iconName: 'user',
      secondIconName: 'trash',
      iconColor: 'explicitError',
      toolTipMessage: 'Deletar clientes sem dados',
      disabled: false,
    },
  ];

  return (
    <Grid container rowGap={1}>
      {buttons.map((button, index) => (
        <Box
          key={index}
          sx={{
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Tooltip
            title={button.toolTipMessage ? button.toolTipMessage : ''}
            disableHoverListener={button.toolTipMessage ? false : true}
          >
            <Button
              variant="outlined"
              onClick={() => onClickButton(button)}
              disabled={button.disabled}
              fullWidth
              sx={{ justifyContent: 'start', pt: button.secondIconName ? 2 : '' }}
              startIcon={
                button.secondIconName ? (
                  <FontAwesomeDoubleIconStatus
                    icon1={button.iconName}
                    icon2={button.secondIconName}
                    status={button.iconColor}
                    status2={button.status2}
                  />
                ) : (
                  <FontAwesomeIcon icon={button.iconName} color={button.iconColor} />
                )
              }
              color={button.iconColor}
            >
              <Box component="span" sx={{ ml: button.secondIconName ? 1.25 : '0' }}>
                {button.title}
              </Box>
            </Button>
          </Tooltip>
        </Box>
      ))}
    </Grid>
  );
}

export { ActionButton, MainButtons };

